import data from "../../data/index.json";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from './Modal';
import React, { useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

export default function BlogShowcase() {
  const [showModal, setShowModal] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);

  const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <FaArrowLeft size={35} color="#7b7b7b" />
      </div>
    );
  };

  const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <FaArrowRight size={35} color="#7b7b7b" />
      </div>
    );
  };

  // Function to open the modal and disable background scrolling
  const openModal = (blog) => {
    setSelectedBlog(blog);
    setShowModal(true);
    document.body.classList.add('modal-open'); // Prevents background scrolling
  };

  // Function to close the modal and enable background scrolling
  const closeModal = () => {
    setShowModal(false);
    setSelectedBlog(null);
    document.body.classList.remove('modal-open'); // Enables background scrolling
  };

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768, // Use 768px for tablet resolutions
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  };

  return (
    <section id="Blog" className="blog--section">
      <div className="blog--title--container">
        <p className="blog--section--subtitle">The Latest Articles</p>
        <h1 className="blog--section-title--color">From The Blog</h1>
      </div>
      <Slider {...settings}>
        {data?.blog?.map((item) => {
          const { link, image, title, excerpt, author, date } = item;
          return (
            <div
              key={link}
              className="blog--section--card"
              onClick={() => openModal(item)} // Call openModal when a card is clicked
            >
              <div className="blog--section--link">
                <img src={image} alt={`Image for ${title}`} className="blog--section--img" />
                <div className="blog--section--content">
                  <h2 className="blog--section--title">{title}</h2>
                  <p className="blog--section--excerpt">{excerpt}</p>
                  <p className="blog--section--author">By {author} | {date}</p>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
      {selectedBlog && (
        <Modal show={showModal} onClose={closeModal} blog={selectedBlog} />
      )}
    </section>
  );
}
