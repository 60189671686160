import React from 'react';

export default function AboutMe() {
  return (
    <section id="AboutMe" className="about--section">
      <div className="about--section--title--container">
        <div className="about--section--subtitle">An Introduction</div>
        <h1 className="about--section--title">About Me</h1>
      </div>
      <div className="about--section--grid">
        <div className="about--section--content">
          <p className="about--section--description">
          Hey there! I'm John, and if you're into tech, business, and a good challenge, we’ll get along just fine. Graduating with a Bachelor of Science from the University of Oregon, my education in business administration excites me to deploy my findings to merge operations, software development, and compliance to create smarter, more efficient solutions. Whether it's optimizing processes or tackling complex challenges, I thrive on my mission to continue learning, researching, and innovating to make things work better.
          </p>
          <br />
          <p className="about--section--description">
          At Amazon, what started as a way to make ends meet while being a full-time student turned into an invaluable learning experience. This first job taught me essential life skills, like time management and resilience, and allowed me to build some incredible professional relationships within the company. One thing I’ve always prioritized is providing excellent customer service, even when customers can sometimes be a little difficult (hey, we’ve all been there). Along the way, I noticed a flaw in the Amazon returns process that was causing frustration for both customers and my coworkers. That’s when I decided to take action. I developed software tools that streamlined the process, making it easier for my coworkers to do their jobs and ensuring that customers had a much smoother experience. Once my tool was in place, customers didn’t notice the issue, my coworkers dealt with less stress, and the returns process became more efficient. It was one of the most rewarding challenges I’ve tackled, knowing that I made a difference both for my team and for Amazon customers.
          </p>
          <br />
          <p className="about--section--description">
          Elected As the Risk Management Executive for Phi Delta Theta, I wasn’t just creating policies—I was setting up regulatory compliance, strategy, and policies that helped safeguard the chapter and ensure its long-term success. Joining as a founding member, the organization has grown to over 65 active members, reestablished our national charter, and reestablished our association with the University of Oregon. I am incredibly proud of what we have accomplished as a new chapter at the university as well as our contributions to the local community and volunteering efforts. Unfortunately, Fraternities on American college campuses have a negative public perception, and it's incredibly easy to lose public support and gain a negative reputation. This is why it was important to create policies, systems, and interventions to prevent crises to ensure health, safety, academic success, and personal development.
          </p>
          <br />
          <p className="about--section--description">
          What excites me most are roles that allow me to continue learning and contribute to meaningful missions. Over the pandemic learning the principles of software development, I’ve made an online community through the various game servers I’ve built. These servers allow people from all over the world to play games, have fun, and create new friendships. What is interesting about the creation of these servers is how much work and structure are needed to have this platform succeed. My skills from college, Amazon, and Phi Delt have come in handy in all sorts of unexpected and tricky situations. I’ve learned that success comes from collaboration, open-mindedness, and adaptability.
          </p>
          <br />
          <p className="about--section--description">
          I’m excited about opportunities where I can bring this mindset—where every day presents a new chance to learn, grow, and make an impact with others who share the same passion for innovation.
          </p>
        </div>
        <div className="about--section--img--content">
          <div className="about--section--img">
          <img className="img" src="./img/jack.png" alt="John Gwynn Profile Picture" />
          </div>
          <div className="about--section--button">
            <a className="btn-link" href="https://www.linkedin.com/in/jgwynn8/">
              <button className="about--section--shrink-border">
                <svg className="about--section--svg-icon" xmlns="http://www.w3.org/2000/svg" height="45" width="45" viewBox="0 0 448 512">
                  <path fill="#FFF" d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>
                </svg>
                Let’s Connect!
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}