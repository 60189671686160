import data from "../../data/index.json"

export default function MyPortfolio() {
    return <section className="portfolio--section" id="MyPortfolio">
        <div className="portfolio--title--container">
            <div className="portfolio--container--subtitle">
                <p className="portfolio--subtitle--style">Recent Projects From</p>
            </div>
            <div className="portfolio--container--title">
                <h1 className="portfolio--title--style">My Portfolio</h1>
            </div>
        </div>
        <div className="portfolio--card--container">
            {data?.portfolio?.map((item, index)=> (
            <div key={index} className="portfolio--section--card">
                <div className="portfolio--section--img">
                    <img className="portfolio--section--img--style" src={item.src} alt="Placeholder"/>
                </div>
                <div className="portfolio--section--card--content">
                    <div>
                        <h3 className="portfolio--card--title">{item.title}</h3>
                        <p className="text-md">{item.description}</p>
                    </div>
                <svg xmlns="http://www.w3.org/2000/svg" className="text-sm portfolio--link" height={item.h} width={item.w} viewBox={item.vb}><path fill={item.fill} d={item.d}/></svg>
                </div>
            </div>
            )
        )}
        </div>
    </section>
}