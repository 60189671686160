import React from 'react';
import { useParams } from 'react-router-dom';
import blogData from "../data/index.json";

const BlogPost = () => {
  const { link } = useParams();

  // Find the blog post by link
  const blogPost = blogData.blog.find(post => post.link === link);

  if (!blogPost) {
    return <div>Blog post not found</div>;
  }

  return (
    <section>
    <div>
      <h1>{blogPost.title}</h1>
      <img src={blogPost.image} alt={blogPost.title} />
      <p>{blogPost.description}</p>
      <p>By {blogPost.author} | {blogPost.date}</p>
    </div>
    </section>
  );
};

export default BlogPost;