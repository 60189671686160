import AboutMe from "../AboutMe";
import Footer from "../Footer";
import ContactMe from "../ContactMe";
import HeroSection from "../HeroSection";
import MyPortfolio from "../MyPortfolio";
import BlogShowcase from "../Blog";


export default function Home() {
    return(
        <>
            <HeroSection /> 
            <AboutMe />
            <BlogShowcase />
            <MyPortfolio />
            <ContactMe />
            <Footer />
        </>
    )
}