import './App.css';
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Pages/Home/Navbar";
import Home from "./Pages/Home/Homescreen";
import BlogPost from "./Pages/BlogPost"; // Import the new BlogPost component

function App() {
  return (
    <div className="App">
      <Router>
        <div>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/blog/:link" component={BlogPost} /> {/* Dynamic route for blog posts */}
            <Route path="*" element={<div>404 Not Found.. 😭</div>} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
